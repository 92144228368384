<template>
  <div
  
    
    class="image-uploader flex-center-vertically text-center p-1"
    style="border: 1px dashed"
    :style="{
      height: `${ph}px`,
      width: `${pw}px`,
    }"
  >
    <img v-if="image != ''" class="img-fluid p-1" :src="image" />
    <img class="img-fluid p-1" v-else src="@/assets/images/avatars/no_image.jpg" />
  </div>
  
</template>

<script>
export default {
  components: {},
  props: {
    image: {
      type: String,
      default: "",
    },
    ph: {
      type: String,
      default: "140",
    },
    pw: {
      type: String,
      default: "140",
    },
  },
};
</script>

<style scoped>
.flex-center-vertically {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>

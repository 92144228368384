<template>
  <div>
    <div class="ml-2 mr-2">
      <h5>{{ $t("general.stepForOkrMidYearReview") }}</h5>
      <b-card-text>
        <p v-html="getTranslate(info, 'midYearReview', $i18n.locale)"></p>
      </b-card-text>
    </div>
    <div class="p-2">
      <validation-observer ref="createForm" #default="{ invalid }">
        <n-form-confirmation
          key="createForm"
          :form="$refs.createForm"
          :disabled="invalid"
        >
          <n-input-view :fields="fields" v-model="data" :initValue="initData">
            <template #objectives>
              <b-tabs pills class="tab-card mb-2" v-model="tabIndex">
                <b-tab
                  v-for="(type, index) in objectiveTypes"
                  :key="`${type}-${index}`"
                  @click="getCurrentTab(type)"
                >
                  <template #title>
                    <span>
                      {{ getTranslate(type, "title", $i18n.locale) }}
                    </span>
                  </template>
                  <template>
                    <b-card-text
                      class="mb-2 mt-2"
                      v-if="objectiveType.descriptionEn"
                    >
                      <h5>
                        {{
                          getTranslate(
                            objectiveType,
                            "description",
                            $i18n.locale
                          )
                        }}
                      </h5>
                    </b-card-text>
                    <p v-else class="mt-3"></p>
                    <okr-setting-header v-show="type.type != 'comment'" />
                    <app-collapse accordion type="margin">
                      <app-collapse-item
                        :is-visible="true"
                        :title="`${item}-${index}`"
                        v-for="(item, index) in objectives.filter(
                          (item) => item.objectiveTypeId === objectiveType.id
                        )"
                        :key="`${item}-${index}`"
                        class="mb-1 mt-1"
                        :class="{ 'collapse-item-bg': index % 2 != 0 }"
                      >
                        <template #header>
                          <okr-setting-question
                            v-if="item.objectiveTypeId == 1"
                            :no="index + 1"
                            :question="item.title"
                            :weight="item.weight"
                          >
                          </okr-setting-question>
                          <okr-setting-question
                            v-else
                            :no="index + 1"
                            :weight="item.weight"
                          >
                            <template #question>
                              <div>
                                <h6>
                                  {{
                                    getTranslate(
                                      item.objective,
                                      "description",
                                      $i18n.locale
                                    )
                                  }}
                                </h6>
                                <p>
                                  {{
                                    getTranslate(
                                      item.objective,
                                      "title",
                                      $i18n.locale
                                    )
                                  }}
                                </p>
                              </div>
                            </template>
                          </okr-setting-question>
                        </template>
                        <okr-setting-answer>
                          <template #keyresult>
                            <div
                              v-for="(result, idx) in item.objectiveKeyResults"
                              :key="`${result}-${idx}`"
                              :value="result.title"
                              v-show="result.title != null"
                            >
                              <hr />
                              <b-row>
                                <b-col>
                                  <label class="text-muted ml-2"
                                    >{{ $t('field.keyResult') }}</label
                                  >
                                  <b-input-group class="shadow-none">
                                    {{ idx + 1 }}.
                                    <b-form-textarea
                                      disabled
                                      v-model="result.title"
                                      class="mb-1 ml-1"
                                      autocomplete="off"
                                      rows="2"
                                      max-rows="3"
                                    />
                                  </b-input-group>
                                </b-col>
                                <b-col>
                                  <label class="text-muted"
                                    >{{ $t("field.employeeReviewMidYear") }}
                                  </label>
                                  <b-form-textarea
                                   disabled
                                    v-model="result.remark"
                                    class="mb-1"
                                    autocomplete="off"
                                    rows="2"
                                    max-rows="3"
                                  />
                                </b-col>
                                <b-col cols="2">
                                  <label class="text-muted">{{ $t('field.status') }} </label>
                                  <div>
                                    <b-badge
                                        v-if="result.statusNameEn != null"
                                        pill
                                        :variant="
                                          getMidYearStateColor(
                                            result.statusNameEn
                                          )
                                        "
                                        badge-glow
                                      >
                                        {{ getTranslate(result,'statusName',$i18n.locale) }}
                                      </b-badge>
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                            <hr>
                            <div>
                              <b-row align-h="center">
                                <b-col cols="12">
                                  <h5 class="mb-2">{{
                                    $t("field.directSupervisorComment")
                                  }}</h5>
 
                                  <b-form-textarea
                                    disabled
                                    v-model="item.supervisorComment"
                                    rows="3"
                                    max-rows="5"
                                  ></b-form-textarea>
                                </b-col>
                              </b-row>
                              <br />
                            </div>
                          </template>
                        </okr-setting-answer>
                      </app-collapse-item>
                    </app-collapse>

                    <!-- Supervisor Comment Here -->
                    <div
                      v-show="type.type == 'comment'"
                      class="shadow-sm p-2 mb-2 bg-white rounded"
                    >
                      <div
                        v-for="(commentQuestion, idx) in comments"
                        :key="`${commentQuestion}-${idx}`"
                      >
                        <h5 v-if="commentQuestion.titleKm != null">
                          {{ idx + 1 }}. {{
                            getTranslate(commentQuestion, "title", $i18n.locale)
                          }} - <span class="text-muted">{{ $t('field.optional') }}</span>
                        </h5>
                        <h5 v-else>{{ idx + 1 }}. {{ commentQuestion.titleEn }}  - <span class="text-muted">{{ $t('field.optional') }}</span></h5>
                        <b-form-textarea
                          disabled
                          v-model="commentQuestion.comment"
                          class="mb-4 mt-2"
                          rows="4"
                        />
                      </div>
                    </div>
                    <okr-setting-footer
                      v-show="type.type != 'comment'"
                      :total-weight="calculateWeight(objectiveType.id)"
                    />
                  </template>
                </b-tab>
              </b-tabs>
            </template>
          </n-input-view>

          <div class="w-100 text-right">
            <n-button-loading
              type="button"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              :loading="loading"
              class="mt-1"
              @submit="back"
            >
              {{ $t("button.back") }}
            </n-button-loading>
          </div>
        </n-form-confirmation>
      </validation-observer>
    </div>
  </div>
</template>
        
        <script>
import {
  BCol,
  BRow,
  BCard,
  BFormRadioGroup,
  BFormRadio,
  BFormGroup,
  BCardText,
  BFormInput,
  BButton,
  BFormTextarea,
  BInputGroup,
  BTab,
  BTabs,
  BBadge,
  BModal,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import BCardCode from "@core/components/b-card-code";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Repository from "@/repositories/RepositoryFactory";
import { getTranslate, getMidYearStateColor, State } from "@/libs/helper";
import OkrSettingQuestion from "@/components/OkrSettingQuestion.vue";
import OkrSettingAnswer from "@/components/OkrSettingAnswer.vue";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import NInputView from "@/components/NInputView";
import NButtonSaveDraft from "@/components/NButtonSaveDraft.vue";
import NButtonApprove from "@/components/NButtonApprove.vue";
import OkrSettingHeader from "@/components/OkrSettingHeader.vue";
import OkrSettingFooter from "@/components/OkrSettingFooter.vue";
import ProfileInformation from "@/components/ProfileInformation.vue";
import FormInput from "./formInput";
import vSelect from "vue-select";
const ObjectiveTypeRepository = Repository.get("objectiveType");
const StepInformationRepository = Repository.get("stepInformation");
const MidYearReview = Repository.get("midYearReview");
export default {
  components: {
    BFormInput,
    BCard,
    BCol,
    BRow,
    BFormRadioGroup,
    BFormRadio,
    BCardText,
    BFormGroup,
    BButton,
    BFormTextarea,
    BInputGroup,
    BTab,
    BTabs,
    BBadge,
    BModal,
    BSpinner,

    BCardCode,
    AppCollapse,
    AppCollapseItem,
    OkrSettingAnswer,
    OkrSettingQuestion,
    NInput,
    NButtonLoading,
    NFormConfirmation,
    NButtonSaveDraft,
    NButtonApprove,
    OkrSettingHeader,
    OkrSettingFooter,
    ProfileInformation,
    NInputView,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    okrSetting: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      tabIndex: 0,
      info: {},
      data: {
        okrSettingId: null,
        state: null,
        year:null,
        objectives: [],
        comments: [],
      },
      initData: {},
      objectiveType: {},
      objectiveTypes: [],
      objectives: [],
      statuses: [],
      comments: [],
      totalWeight: 0,
      loading: false,
      query: {
        page: Number(this.$route.query.page) || 1,
        order: "sequence_order",
        sort: "ASC",
      },
      getTranslate,
      getMidYearStateColor,
      State,
    };
  },
  created() {
    this.show();
    this.getStepInformation();
    this.getObjectiveType();
  },
  methods: {
    getStepInformation() {
      this.loading = true;
      StepInformationRepository.show()
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.info = data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getCurrentTab(objectiveType) {
      this.objectiveType = objectiveType;
    },
    getObjectiveType() {
      this.loading = true;
      const params = {
        ...this.query,
      };
      ObjectiveTypeRepository.index(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.objectiveTypes = [...data.list];
            this.objectiveTypes.push({
              id: null,
              type: "comment",
              titleEn: "Direct Supervisor's Comment",
              titleKm: "មតិយោបល់របស់អ្នកគ្រប់គ្រងផ្ទាល់​",
            });

            if (this.objectiveTypes.length > 0) {
              this.objectiveType = this.objectiveTypes[0];
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    calculateWeight(objectiveTypeId) {
      var total = 0;
      this.objectives.forEach((item) => {
        if (item.objectiveTypeId == objectiveTypeId) {
          total += Number(item.weight);
        }
      });

      return total;
    },

    show() {
      this.loading = true;
      const params = `${this.okrSetting.midYearReview.id}`;
      MidYearReview.show(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.initData = {
              ...data,
            };
            this.objectives = this.initData.objectives;
            this.comments = [...this.initData.comments];
            this.objectives.forEach((objective) => {
              objective.objectiveKeyResults.forEach((keyResult) => {
                keyResult.statuses = this.statuses;
              });
            });
          }
        })
        .then(() => {
          this.loading = false;
        });
    },

    back() {
      this.$router.push({
        name: `dashboard`,
      });
    },
  },
  setup() {
    const fields = FormInput;
    return { fields };
  },
};
</script>
    
<template>
  <b-modal
    ref="reject-modal"
    :id="modalId"
    centered
    size="lg"
    title-class="text-danger"
    :title="$t('field.reason')"
    ok-title="Reject"
    ok-variant="danger"
    cancel-variant="outline-secondary"
  >
    <p>{{ description }}</p>
    <template #modal-footer>
      <b-button
        @click="hideModal"
        class="btn btn-sm"
        variant="outline-secondary"
        >{{ $t('button.close') }}</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import { BModal,BButton } from "bootstrap-vue";
export default {
  props: {
    modalId: {
      type: String,
      default: "reject-modal-info",
    },
    description: {
      type: String,
      default: "",
    },
  },
  components: {
    BModal,
    BButton,
  },
  methods:{
    hideModal() {
      this.$refs["reject-modal"].hide();
    },
    showModal() {},
  }
};
</script>

<style>
</style>
<template>
  <div>
    <b-overlay
      :show="loading"
      :style="{
        width: fullWidth ? '' : `${pw}px`,
        height: fullWidth ? '' : `${ph}px`,
      }"
    >
      <div
        v-if="isDisable"
        class="image-uploader flex-center-vertically text-center"
        :style="{
          borderColor: activeColor,
          cursor: readonly ? '' : 'pointer',
          backgroundColor: readonly ? secondary : '',
          width: fullWidth ? '' : `${pw}px`,
          height: fullWidth ? '' : `${ph}px`,
        }"
      >
        <div v-if="(!uploadImage && !image) || !value">
          <div
            :style="{
              color: readonly ? '#ffffff' : activeColor,
            }"
          >
            {{ $t("field.uploadSignature") }}
          </div>
        </div>
        <div v-else>
          <img
            class="img-fluid"
            :class="{
              'p-1': !readonly,
            }"
            :src="uploadImage ? uploadImage : image"
            alt=""
            :style="{
              height: '80px',
            }"
          />
        </div>
      </div>
      <div
        v-else
        class="image-uploader flex-center-vertically text-center"
        :style="{
          borderColor: activeColor,
          cursor: readonly ? '' : 'pointer',
          backgroundColor: readonly ? secondary : '',
          width: fullWidth ? '' : `${pw}px`,
          height: fullWidth ? '' : `${ph}px`,
        }"
        ref="dragDropBox"
        @click="chooseImage"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
      >
        <div v-if="(!uploadImage && !image) || !value">
          <div
            :style="{
              color: readonly ? '#ffffff' : activeColor,
              border: ' 1px dashed',
            }"
            style="border: 1px dashed;padding: 8px;"
          >
            {{ $t("field.uploadSignature") }}
          </div>
        </div>
        <div v-else>
          <img
            class="img-fluid"
            :class="{
              'p-1': !readonly,
            }"
            :src="uploadImage ? uploadImage : image"
            alt=""
            :style="{
              height: '80px',
            }"
          />
        </div>
      </div>
      <input
        type="file"
        style="display: none"
        multiple
        name="fields[assetsFieldHandle][]"
        id="assetsFieldHandle"
        class="w-px h-px opacity-0 overflow-hidden absolute"
        @change="onChange"
        ref="fileInput"
        accept=".png"
      />
    </b-overlay>
    <b-button
      class="mt-1"
      variant="danger"
      size="sm"
      @click="removeImage"
      :style="{
        width: fullWidth ? '' : `${pw}px`,
      }"
      v-if="(!readonly && isOptional && value) || showDeleteButton"
    >
      {{ $t("button.delete") }}
    </b-button>
  </div>
</template>
  
  <script>
import { $themeConfig } from "@themeConfig";
import Repository from "@/repositories/RepositoryFactory";
import {
  BOverlay,
  BModal,
  BCard,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";
import NPagination from "@/components/NPagination";
import vSelect from "vue-select";
import Compressor from "compressorjs";

const FileRepository = Repository.get("file");

export default {
  delimiters: ["${", "}"],

  components: {
    BOverlay,
    BModal,
    BCard,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    vSelect,
    NPagination,
  },

  data: function () {
    return {
      loading: false,
      filelist: [],
      uploadImage: null,
      imageHistories: [],
      searchText: "",
      page: 1,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
    };
  },

  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },

  props: {
    image: {},
    value: {},
    size: {},
    pw: {},
    ph: {},
    path: {
      type: String,
      default: "image",
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    isOptional: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Image",
    },
    readonly: {},
    uuid: {
      default: "",
    },
    compressQuality: {
      default: 0.6,
    },
    compressMineType: {
      default: "image/jpeg",
    },
    showDeleteButton: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    perPage() {
      this.listFile();
    },
  },

  methods: {
    onHide() {},
    search() {
      this.listFile();
    },
    reset() {
      this.searchText = "";
      this.listFile();
    },
    viewHistory() {
      this.$bvModal.show(`upload-history-${this.uuid}`);
      this.listFile();
    },
    listFile(page = 1) {
      FileRepository.index({
        filePath: this.path,
        search: this.searchText,
        fileType: "image",
        page: page,
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.imageHistories = [...data.list];
            this.total = data.total;
          }
        })
        .catch()
        .then(() => {});
    },
    selectImage(image) {
      this.$emit("input", image.id);
      this.$emit("change", image);
      this.uploadImage = image.fileUrl;
      this.$bvModal.hide(`upload-history-${this.uuid}`);
    },
    onChange() {
      if (this.readonly) {
        return;
      }

      this.filelist = [...this.$refs.fileInput.files];
      let self = this;
      if (this.filelist[0]) {
        new Compressor(this.filelist[0], {
          quality: this.compressQuality,
          mimeType: this.compressMineType,
          success(result) {
            var reader = new FileReader();
            reader.onload = (e) => {
              const file = {
                filePath: self.path,
                file: e.target.result,
                fileName: self.filelist[0].name,
                fileSize: self.filelist[0].size,
                fileType: "image",
              };
              self.upload(file);
            };
            reader.readAsDataURL(result);
          },
        });
      }
    },
    upload(file) {
      this.loading = true;
      FileRepository.upload(file)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.$emit("input", data.id);
            this.$emit("change", data);
            this.uploadImage = data.fileUrl;
          }
        })
        .catch()
        .then(() => {
          this.loading = false;
        });
    },
    removeImage() {
      this.$emit("delete", null);
      this.$emit("input", null);
      this.$emit("change", null);
      this.uploadImage = null;
    },
    remove() {
      this.filelist.splice(0, 1);
      this.$emit("input", null);
      this.$emit("change", null);
    },
    dragover(event) {
      if (this.readonly) {
        return;
      }

      event.preventDefault();
      this.activeColor = this.primary;
    },
    dragleave(event) {
      if (this.readonly) {
        return;
      }
      this.activeColor = this.secondary;
    },
    drop(event) {
      if (this.readonly) {
        return;
      }
      event.preventDefault();
      this.$refs.fileInput.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
    },

    chooseImage() {
      if (this.readonly) {
        return;
      }
      this.$emit("blur", null);
      this.$refs.fileInput.value = null;
      this.$refs.fileInput.click();
    },
  },
  setup() {
    // App Name
    const { primary, secondary } = $themeConfig.color;

    let activeColor = secondary;

    return {
      primary,
      secondary,
      activeColor,
    };
  },
};
</script>
  
  <style scoped>
.flex-center-vertically {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>
  
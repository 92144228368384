<template>
    <div class="p-2">
      <h3 class="text-center">{{ $t('general.ratingScale') }}</h3>
      <b-card-text class="mt-3">
        <b-row class="text-center text-white">
            <b-col cols="3" style="background-color: #40b04f;">5</b-col>
            <b-col cols="2" style="background-color: #92d050;">4</b-col>
            <b-col cols="2" style="background-color: #f8c004;">3</b-col>
            <b-col cols="2" style="background-color: #f10104;">2</b-col>
            <b-col cols="3" style="background-color: #c00001;">1</b-col>
        </b-row>
        <b-row class="text-center">
            <b-col cols="3" class="py-2" style="background-color: #F3F3F2;">
                <h5>លទ្ធផលការងារឆ្នើម ហើយលើសពីការរំពឹងទុកគ្រប់គោលដៅទាំងអស់</h5>
                <h5>Exceptional</h5>
                <h5>'E'</h5>
                <br>
                <h4 class="text-danger">97-100%</h4>
            </b-col>
            <b-col cols="2" class="py-2" style="background-color: #F3F3F2;">
                <h5>លទ្ធផលការងារល្អប្រសើរ ហើយហួសពីការរំពឹងទុក</h5>
                <h5>Superior</h5>
                <h5>'SPR'</h5>
                <br>
                <h4>91-96.99%</h4>
            </b-col>
            <b-col cols="2" class="py-2" style="background-color: #F3F3F2;">
                <h5>លទ្ធផលការងារល្អ (សម្រេចបានតាមគោលដៅកំណត់)</h5>
                <h5>Satisfactory</h5>
                <h5>'Sat'</h5>
                <br>
                <h4>70-90.99%</h4>
            </b-col>
            <b-col cols="2" class="py-2" style="background-color: #F3F3F2;">
                <h5>លទ្ធផលការងារមានកម្រិតទាបនិងត្រូវការធ្វើឱ្យប្រសើរឡើង</h5>
                <h5>Improvement Required</h5>
                <h5>'IR'</h5>
                <br>
                <h4>50-69.99%</h4>
            </b-col>
            <b-col cols="3" class="py-2" style="background-color: #F3F3F2;">
                <h5>លទ្ធផលការងារមានកម្រិតទាបនិងមិនអាចទទួលយកបាន</h5>
                <h5>Unacceptable</h5>
                <h5>'U'</h5>
                <br>
                <br>
                <h4>{{ "<=49.99%" }}</h4>
            </b-col>
        </b-row>
        <h5 class="py-2">I. Business</h5>
        <b-row class="text-left">
            <b-col cols="3" class="py-2" style="background-color: #F3F3F2;">
                <p
                    v-html="'សម្រេចបានលើសពីការរំពឹងទុកនិងគោលដៅទាំងអស់ដែលបានកំណត់ហើយបានចូលរួមចំណែកយ៉ាងខ្លាំងដល់នាយកដ្ឋានរបស់ខ្លួនឬអាជីវកម្ម</br></br>Significantly exceeded every expectation and goal set for their job, and made highly visible contributions to the department and/or Business;</br></br>- Achieved additional assignment (minimum 2) while also achieving core responsibilities. '"
                ></p>
            </b-col>
            <b-col cols="2" class="py-2" style="background-color: #F3F3F2;">
                <p
                    v-html="'សម្រេចបាននូវគោលដៅសំខាន់ៗ ហើយមានសមត្ថភាពក្នុងការបំពេញការងារស្នូលរបស់ខ្លួនប្រកបដោយគុណភាព</br></br>Clearly exceeded all “KEY” goals</br>Core job responsibilities were also completed with good quality;</br></br>- Achieved additional assignment (minimum 1) while also achieving core responsibilities. '"
                ></p>
            </b-col>
            <b-col cols="2" class="py-2" style="background-color: #F3F3F2;">
                <p
                    v-html="'សម្រេចបាននូវគោលដៅសំខាន់ៗទាំងអស់</br></br>Fully achieved the most important or  “KEY” goals</br></br>- Did not achieve additional assignment. '"
                ></p>
            </b-col>
            <b-col cols="2" class="py-2" style="background-color: #F3F3F2;">
                <p
                    v-html="'មិនសម្រេចបាននូវគោលដៅដែលបានកំណត់ទាំងអស់ទេ ឬមិនសម្រេចបាននូវគោលដៅណាមួយ ឬបានបំពេញគោលដៅ ប៉ុន្តែមិនបានបំពេញការងារស្នូលរបស់ខ្លួនដោយជោគជ័យ </br></br>Falls a little short on key goals, or significantly short on one.</br></br>OR Delivers against key goals, but under-delivers against core job responsibilities'"
                ></p>
            </b-col>
            <b-col cols="3" class="py-2" style="background-color: #F3F3F2;">
                <p
                    v-html="'ស្នាដៃការងារមិនឆ្លើយតបនឹងក្តីរំពឹងទុកជាដរាប។ មិនសម្រេចបាននូវគោលដៅសំខាន់ៗភាគច្រើន ឬការរីកចម្រើនចំពោះគោលដៅចម្បងដែលបានកំណត់ ឬការងារស្នូលរបស់ខ្លួនមិនបានធ្វើ </br></br>Performance was consistently below expectation. Falls short on most of key goals by a significant margin or progress towards key goals or core job responsibilities was not made.'"
                ></p>
            </b-col>
        </b-row>
        <h5 class="py-2">II. Continued Professional Development & Career Aspiration </h5>
        <b-row class="text-left">
            <b-col cols="3" class="py-2" style="background-color: #F3F3F2;">
                <p
                    v-html="'តែងតែ(១០០%)</br>Always modeled and demonstrated: </br>-Strong VPL values & competencies in achieving results, including playing a role model for all team (e.g; attendance);</br>-Highly skilled leadership and teamworkin both the achievement of objectives and daily operations which are clearly acknowledged by manager and peers; </br>- Has positive and growth mindset; and </br>- Has a learning attitude and take knowledge to improve daily job. '"
                ></p>
            </b-col>
            <b-col cols="2" class="py-2" style="background-color: #F3F3F2;">
                <p  v-html="'ជានិច្ចកាល/ជារឿយៗ</br>Consistently</br>demonstrated: </br>-Vattanac Properties Limited (VPL) values and competencies in achieving results;</br>-Highly skilled leadership and teamworkin both the achievement of objectives and daily operations, which are acknowledged by manager and peers;'"
                ></p>
            </b-col>
            <b-col cols="2" class="py-2" style="background-color: #F3F3F2;">
                <p
                    v-html="'ជាទៀងទាត់ </br>Regularly </br>demonstrated</br>-VPL values and competencies in achieving results,</br>-Highly skilled leadership and teamworkin both the achievement of objectives and daily operations, which are acknowledged by manager and peers. '"
                ></p>
            </b-col>
            <b-col cols="2" class="py-2" style="background-color: #F3F3F2;">
                <p
                    v-html="'កម្រ </br>Rarely </br>demonstrated</br>demonstrated VPL values and competencies in achieving results,or clearly have some behavioural issues; '"
                ></p>
            </b-col>
            <b-col cols="3" class="py-2" style="background-color: #F3F3F2;">
                <p
                    v-html="'កម្រ </br>Rarely </br>បង្ហាញពីគុណតម្លៃក្រុមហ៊ុន និងមិនមានសមត្ថភាពក្នុងការសម្រេចបាននូវលទ្ធផលdemonstrated VPL values and competencies in achieving results,And had high impact behavioural issues'"
                ></p>
            </b-col>
        </b-row>
        <p class="pt-4">
            <span style="font-weight: 700;">Growth mindset</span>
             means that you thrive on challenge - and you don't see failure as a way to disappoint yourself but a springboard for growth & ability development.
        </p>
        <p>
            <span style="font-weight: 700;">Learning attitude</span>
            is a self-initiated & voluntary attitude stemming from natural curiosity & motivation for personal & professional development. 
        </p>
      </b-card-text>
    
    </div>
  </template>
  
  <script>
  import {} from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  
  import BCardCode from "@core/components/b-card-code";
  import { BTabs, BTab, BCardText, BCard, BRow,BCol} from "bootstrap-vue";
  export default {
    components: {
      BCardCode,
      BTabs,
      BTab,
      BCardText,
      BCard,
      BRow,
      BCol,
    },
    directives: {
      Ripple,
    },
    data() {
      return {};
    },
    setup() {
      const resource = "page-content";
      const route = "page-content";
  
      return { resource };
    },
  };
  </script>
  
<template>
      <b-card class="mt-1 card-head">
      <template #header>
        <b-row align-v="center" style="width: 100%">
          <b-col cols="4">
            <h6 class="text-white">{{ $t("field.totalWeight") }}</h6>
          </b-col>
          <b-col cols="6">
            <h6></h6>
          </b-col>
          <b-col cols="2">
            <h5 class="text-center text-white">{{ totalWeight }}%</h5>
          </b-col>
        </b-row>
      </template>
    </b-card>
</template>

<script>
import { BCol, BRow, BCard } from "bootstrap-vue";

export default {
  components: {
    BCol,
    BRow,
    BCard,
  },
  props: {
    totalWeight: {
      default: 0,
    },
  },
};
</script>

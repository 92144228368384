<template>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="outline-secondary"
      class="mt-1 mr-1"
      right
      split
      @click="submit(defaultOption)"
      :disabled="loading"
      v-bind="$attrs"
    >

    <b-spinner small label="Spinning" v-if="loading"></b-spinner>
      {{ $t('button.saveDraft') }}
    </b-button>
  </template>
  
  <script>
  import { BDropdown, BDropdownItem, BSpinner, BButton } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  
  export default {
    components: {
      BDropdown,
      BDropdownItem,
      BSpinner,
      BButton,
    },
    directives: {
      Ripple,
    },
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      hideCreate: {
        type: Boolean,
        default: false,
      },
      customCreateRoute: {
        type: String,
        default: "",
      },
      hideUpdate: {
        type: Boolean,
        default: false,
      },
      customUpdateRoute: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        defaultOption: 1,
      };
    },
    mounted() {
      const defaultOption = localStorage.getItem("btnSave");
      this.defaultOption = defaultOption ? Number(defaultOption) : 1;
    },
    methods: {
      submit(type) {
        if (this.loading) {
          return;
        }
  
        this.$emit("submit", type);
      },
      confirm(type) {
        this.$bvModal
          .msgBoxConfirm(this.$t("alert.unableToRevertAfterSave"), {
            title: this.$t("alert.areYouSure"),
            size: "sm",
            buttonSize: "sm",
            okVariant: "primary",
            okTitle: this.$t("button.save"),
            cancelTitle: this.$t("button.cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.$emit("save", type);
            }
          });
      },
      afterSave(type, id) {
        this.defaultOption = type ? type : this.defaultOption;
        localStorage.setItem("btnSave", this.defaultOption);
        this.$router.push({
              name: 'dashboard',
            });
      },
    },
  };
  </script>
  
  <style scoped>
  </style>
  
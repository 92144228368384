<template>
  <b-row align-v="center" style="width: 100%">
    <b-col cols="1" class="p-1">
      <h6>
        {{ getNoNumber(no) }}. <span> <slot name="error"></slot></span>
      </h6>
    </b-col>
    <b-col cols="9">
      <slot name="question">
        <h6 v-html="question"></h6>
      </slot>
    </b-col>
    <b-col cols="2">
      <div class="mr-2">
        <slot name="weight">
          <h5 class="text-center">{{ weight }}%</h5>
        </slot>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { getTranslate, getNoNumber } from "@/libs/helper";
import {
  BCol,
  BRow,
  BCard,
  BFormRadioGroup,
  BFormRadio,
  BFormGroup,
  BCardText,
  BFormInput,
} from "bootstrap-vue";

export default {
  components: {
    BCardCode,
    AppCollapse,
    AppCollapseItem,
    BFormInput,
    BCard,
    BCol,
    BRow,
    BFormRadioGroup,
    BFormRadio,
    BCardText,
    BFormGroup,
  },
  props: {
    no: {
      type: Number,
      default: "",
    },
    question: {
      type: String,
      default: "",
    },
    weight: {
      type: Number,
      default: "",
    },
  },
  data() {
    return {
      getTranslate,
      getNoNumber,
    };
  },
};
</script>
<template>
  <div v-if="data != null">
    <b-row align-v="center">
      <b-col
        ><h4>{{ $t("general.profileInfo") }}</h4></b-col
      >
    </b-row>

    <div style="height: 150px" class="mb-1 mt-1">
      <b-skeleton-img
        v-if="loading"
        no-aspect
        height="150px"
        width="150px"
      ></b-skeleton-img>
      <n-image-view v-else :image="data.employee.profile"  />
    </div>
    <div class="card-headline p-1 rounded">
      <b-row>
        <b-col
          class="col-12 col-md-6 col-lg-5"
          style="border-right: 1px solid lightgrey"
        >
          <b-row>
            <b-col cols="12" md="5" class="pr-0">
              <feather-icon class="mr-1" icon="UserIcon" />
              <span>{{ $t("general.employeeName") }}</span>
            </b-col>
            <b-col>
              <b-skeleton v-if="loading" width="60%"></b-skeleton>
              <h6 v-if="data.employee">: {{ data.employee.name }}</h6>
            </b-col>
          </b-row>
          <b-row class="profile-info">
            <b-col cols="12" md="5">
              <feather-icon class="mr-1" icon="GitCommitIcon" />
              <span>{{ $t("general.position") }}</span>
            </b-col>
            <b-col>
              <b-skeleton v-if="loading" width="80%"></b-skeleton>
              <h6 v-if="data.employee">: {{ data.employee.position.name }}</h6>
            </b-col>
          </b-row>
          <b-row class="profile-info">
            <b-col cols="12" md="5">
              <feather-icon class="mr-1" icon="UserCheckIcon" />
              <span>{{ $t("general.directSupervisor") }}</span>
            </b-col>
            <b-col>
              <b-skeleton v-if="loading" width="100%"></b-skeleton>
              <div v-if="data.employee">
                <h6 v-if="data.employee.manager != null">
                  : {{ data.employee.manager.name }}
                </h6>
                <h6 v-else class="text-muted">: None</h6>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="col-md-6 col-lg-4">
          <b-row>
            <b-col cols="12" md="5">
              <feather-icon class="mr-1" icon="CreditCardIcon" />
              <span>{{ $t("general.employeeId") }}</span>
            </b-col>
            <b-col>
              <b-skeleton v-if="loading" width="60%"></b-skeleton>
              <h6 v-if="data.employee">: {{ data.employee.idCard }}</h6>
            </b-col>
          </b-row>
          <b-row class="profile-info">
            <b-col cols="12" md="5">
              <feather-icon class="mr-1" icon="GitBranchIcon" />
              <span>{{ $t("general.department") }}</span>
            </b-col>
            <b-col>
              <b-skeleton v-if="loading" width="80%"></b-skeleton>
              <h6 v-if="data.employee">
                : {{ data.employee.department.name }}
              </h6>
            </b-col>
          </b-row>
          <b-row class="profile-info">
            <b-col cols="12" md="5">
              <feather-icon class="mr-1" icon="CalendarIcon" />
              <span>{{ $t("general.year") }}</span>
            </b-col>
            <b-col>
              <b-skeleton v-if="loading" width="100%"></b-skeleton>
              <h6 v-else>: {{ new Date().getFullYear() }}</h6>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
      <!-- OKR REJECT MODAL -->
    <div v-if="data.okrSetting != null">
      <reject-modal
        modalId="okr-supervisor-reject-modal"
        :description="data.okrSetting.rejectRemark"
      ></reject-modal>

      <reject-modal
        modalId="okr-hod-reject-modal"
        :description="data.okrSetting.hodRejectRemark"
      ></reject-modal>
      <reject-modal
        modalId="okr-hr-reject-modal"
        :description="data.okrSetting.hrRejectRemark"
      ></reject-modal>
    </div>
      <!-- MID YEAR REJECT MODAL -->
    <div v-if="data.okrSetting != null">
      <div v-if="data.okrSetting.midYearReview != null">
        <reject-modal
          modalId="mid-year-supervisor-reject-modal"
          :description="data.okrSetting.midYearReview.rejectRemark"
        ></reject-modal>
        <reject-modal
          modalId="mid-year-hod-reject-modal"
          :description="data.okrSetting.midYearReview.hodRejectRemark"
        ></reject-modal>
        <reject-modal
          modalId="mid-year-hr-reject-modal"
          :description="data.okrSetting.midYearReview.hrRejectRemark"
        ></reject-modal>
      </div>
    </div>

    <!-- YEAR END REJECT MODAL -->
    <div v-if="data.okrSetting != null">
      <div v-if="data.okrSetting.midYearReview != null">
        <div v-if="data.okrSetting.yearEndReview != null">
          <reject-modal
            modalId="year-end-supervisor-reject-modal"
            :description="data.okrSetting.yearEndReview.rejectRemark"
          ></reject-modal>
          <reject-modal
            modalId="year-end-hod-reject-modal"
            :description="data.okrSetting.yearEndReview.hodRejectRemark"
          ></reject-modal>
          <reject-modal
            modalId="year-end-hr-reject-modal"
            :description="data.okrSetting.yearEndReview.hrRejectRemark"
          ></reject-modal>
        </div>
      </div>
    </div>

    <br v-show="showButton" />
    <div v-show="showButton">
      <b-row align-v="center">
        <b-col cols="12" lg="2">
          <b-button
            :to="{
              name: 'okr-setting',
              params: {
                id: data.okrSetting != null?data.okrSetting.id:'create',
              },
            }"
            variant="primary"
          >
            <feather-icon class="mr-1" icon="EditIcon" />
            {{ $t("button.view_your_okr") }}
          </b-button>
        </b-col>
        <b-col cols="12" md="4" lg="3" class="mt-1">
          <p>
            {{ $t("general.okrSettingState") }}
            <span>
              <b-badge
                v-if="data.okrSetting != null"
                pill
                @click="showOkrRejectModal(data.okrSetting.state)"
                :variant="getState(data.okrSetting.state).color"
                badge-glow
              >
                {{ $t(getState(data.okrSetting.state).name) }}
              </b-badge>
              <b-badge v-else pill :variant="getState('none').color" badge-glow>
                {{ $t(getState("none").name) }}
              </b-badge>
              <feather-icon
                v-if="data.okrSetting != null"
                v-show="data.okrSetting.state == State.REJECTED"
                v-b-modal.okr-supervisor-reject-modal
                class="ml-1"
                icon="InfoIcon"
              ></feather-icon
            >
            <feather-icon
                v-if="data.okrSetting != null"
                v-show="data.okrSetting.state == State.HODREJECTED"
                v-b-modal.okr-hod-reject-modal
                class="ml-1 danger"
                icon="InfoIcon"
              ></feather-icon
            >
            <feather-icon
                v-if="data.okrSetting != null"
                v-show="data.okrSetting.state == State.HRREJECTED"
                v-b-modal.okr-hr-reject-modal
                class="ml-1 danger"
                icon="InfoIcon"
              ></feather-icon
            >
          </span>
          </p>
        </b-col>
        <b-col cols="12" md="4" lg="3" class="mt-1">
          <p>
            {{ $t("general.midYearState") }}
            <span>
              <span v-if="data.okrSetting != null">
                <b-badge
                  v-if="data.okrSetting.midYearReview != null"
                  @click="showMidYearRejectModal(data.okrSetting.midYearReview.state)"
                  pill
                  :variant="getState(data.okrSetting.midYearReview.state).color"
                  badge-glow
                >
                  {{ $t(getState(data.okrSetting.midYearReview.state).name) }}
                </b-badge>
                <b-badge
                  v-else
                  pill
                  :variant="getState('none').color"
                  badge-glow
                >
                  {{ $t(getState("none").name) }}
                </b-badge>
              </span>
              <b-badge v-else pill :variant="getState('none').color" badge-glow>
                {{ $t(getState("none").name) }}
              </b-badge>

              <span v-if="data.okrSetting != null">
                <feather-icon
                  v-if="data.okrSetting.midYearReview != null"
                  v-show="data.okrSetting.midYearReview.state == State.REJECTED"
                  v-b-modal.mid-year-supervisor-reject-modal
                  class="ml-1"
                  icon="InfoIcon"
                ></feather-icon>
                <feather-icon
                  v-if="data.okrSetting.midYearReview != null"
                  v-show="data.okrSetting.midYearReview.state == State.HODREJECTED"
                  v-b-modal.mid-year-hod-reject-modal
                  class="ml-1"
                  icon="InfoIcon"
                ></feather-icon>
                <feather-icon
                  v-if="data.okrSetting.midYearReview != null"
                  v-show="data.okrSetting.midYearReview.state == State.HRREJECTED"
                  v-b-modal.mid-year-hr-reject-modal
                  class="ml-1"
                  icon="InfoIcon"
                ></feather-icon>
              </span>
            </span>
          </p>
        </b-col>
        <b-col cols="12" md="4" lg="3" class="mt-1">
          <p>
            {{ $t("general.yearEndState") }}
            <span>
              <span v-if="data.okrSetting != null">
                <b-badge
                  v-if="data.okrSetting.yearEndReview != null"
                  pill
                  @click="showYearEndRejectModal(data.okrSetting.yearEndReview.state)"
                  :variant="getState(data.okrSetting.yearEndReview.state).color"
                  badge-glow
                >
                  {{ $t(getState(data.okrSetting.yearEndReview.state).name) }}
                </b-badge>
                <b-badge
                  v-else
                  pill
                  :variant="getState('none').color"
                  badge-glow
                >
                  {{ $t(getState("none").name) }}
                </b-badge>
                <span v-if="data.okrSetting != null">
                  <feather-icon
                    v-if="data.okrSetting.yearEndReview != null"
                    v-show="
                      data.okrSetting.yearEndReview.state == State.REJECTED
                    "
                    v-b-modal.year-end-supervisor-reject-modal
                    class="ml-1"
                    icon="InfoIcon"
                  ></feather-icon>
                  <feather-icon
                    v-if="data.okrSetting.yearEndReview != null"
                    v-show="
                      data.okrSetting.yearEndReview.state == State.HODREJECTED
                    "
                    v-b-modal.year-end-hod-reject-modal
                    class="ml-1"
                    icon="InfoIcon"
                  ></feather-icon>
                  <feather-icon
                    v-if="data.okrSetting.yearEndReview != null"
                    v-show="
                      data.okrSetting.yearEndReview.state == State.HRREJECTED
                    "
                    v-b-modal.year-end-hr-reject-modal
                    class="ml-1"
                    icon="InfoIcon"
                  ></feather-icon>
                </span>
              </span>
              <b-badge v-else pill :variant="getState('none').color" badge-glow>
                {{ $t(getState("none").name) }}
              </b-badge>
            </span>
          </p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Repository from "@/repositories/RepositoryFactory";
const EmployeeRepository = Repository.get("employee");
import {
  BCol,
  BRow,
  BCard,
  BFormRadioGroup,
  BFormRadio,
  BFormGroup,
  BCardText,
  BFormInput,
  BButton,
  BSkeleton,
  BBadge,
  BSkeletonImg,
  BTable,
} from "bootstrap-vue";
import { integer } from "@/@core/utils/validations/validations";
import NImageView from "@/components/NImageView.vue";
import RejectModal from "./RejectModal.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { getState, State } from "@/libs/helper";
export default {
  props: {
    userId: {
      type: integer,
      default: 0,
    },
    showButton: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BFormInput,
    BCard,
    BCol,
    BRow,
    BFormRadioGroup,
    BFormRadio,
    BCardText,
    BFormGroup,
    BButton,
    BSkeleton,
    BSkeletonImg,
    BBadge,
    BTable,
    RejectModal,
    NImageView,
    FeatherIcon,
  },

  data() {
    return {
      loading: false,
      data: {},
      queryParam: {
        userId: this.userId,
        year: new Date().getFullYear(),
      },
      getState,
      State,
    };
  },
  created() {
    this.show();
  },
  methods: {

    showOkrRejectModal(state){
      if(state == State.REJECTED){
         this.$bvModal.show('okr-supervisor-reject-modal');
      }
      if(state == State.HODREJECTED){
         this.$bvModal.show('okr-hod-reject-modal');
      }
      if(state == State.HRREJECTED){
         this.$bvModal.show('okr-hr-reject-modal');
      }
    },
    showMidYearRejectModal(state){
      if(state == State.REJECTED){
         this.$bvModal.show('mid-year-supervisor-reject-modal');
      }
      if(state == State.HODREJECTED){
         this.$bvModal.show('mid-year-hod-reject-modal');
      }
      if(state == State.HRREJECTED){
         this.$bvModal.show('mid-year-hr-reject-modal');
      }
    },
    showYearEndRejectModal(state){
      if(state == State.REJECTED){
         this.$bvModal.show('year-end-supervisor-reject-modal');
      }
      if(state == State.HODREJECTED){
         this.$bvModal.show('year-end-hod-reject-modal');
      }
      if(state == State.HRREJECTED){
         this.$bvModal.show('year-end-hr-reject-modal');
      }
    },


    show() {
      this.loading = true;
      EmployeeRepository.showEmployeeProfile(this.queryParam)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.data = { ...data };
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
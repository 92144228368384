<template>
  <b-row align-v="center" style="width: 100%">
        <b-col cols="1" class="p-1">
        <h6></h6>
        </b-col>
        <b-col cols="11">
        <div>
           <h6 class="text-muted">
            {{ $t('breadcrumb.keyResultDescription') }}
        </h6>
        </div>
        <br>
        <div class="mr-4">
          <slot name="keyresult">
          </slot> 
        </div>
        </b-col>
    </b-row>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import {
  BCol,
  BRow,
  BCard,
  BFormRadioGroup,
  BFormRadio,
  BFormGroup,
  BCardText,
  BFormInput,
} from "bootstrap-vue";
export default {
    components: {
    BCardCode,
    AppCollapse,
    AppCollapseItem,
    BFormInput,
    BCard,
    BCol,
    BRow,
    BFormRadioGroup,
    BFormRadio,
    BCardText,
    BFormGroup,
  },

}
</script>
